<ui-library-modal
  [id]="modalId()"
  [size]="size()">
  <ng-container slot="header">
    {{ title() }}
  </ng-container>
  <ng-container slot="content">
    @if (!hasCustomContent()) {
      {{ message() }}
    } @else {
      <ng-content select="[content]"></ng-content>
    }
  </ng-container>
  <ng-container slot="footer">
    <div class="uj-flex uj-gap-c uj-justify-between">
      <button
        (click)="modals.close(modalId())"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-outline
        type="button"
        color="primary">
        {{ cancelText() }}
      </button>
      <button
        (click)="confirmed.emit(true)"
        class="uj-w-full sm:uj-w-auto uj-whitespace-nowrap"
        ui-library-button-solid
        type="button">
        {{ confirmText() }}
      </button>
    </div>
  </ng-container>
</ui-library-modal>
