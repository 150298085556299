import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { ModalService } from '@intemp/unijob-ui2';
import { ModalSizeOptions } from '@intemp/unijob-ui2/organism/modal/modal.component';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent {
  confirmed = output<boolean>();
  modalId = input.required<string>();
  title = input('');
  message = input('');
  confirmText = input('confirm');
  cancelText = input('cancel');
  hasCustomContent = input(false);
  size = input<ModalSizeOptions>('XS');
  constructor(public modals: ModalService) {}

  public open() {
    this.modals.open(this.modalId());
  }
}
