import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationModalComponent } from './confirmation-modal.component';
import { SharedDefaultModule } from '../shared-default/shared-default.module';
import {
  ButtonOutlineComponent,
  ButtonSolidComponent,
  ModalComponent,
} from '@intemp/unijob-ui2';

@NgModule({
  declarations: [ConfirmationModalComponent],
  exports: [ConfirmationModalComponent],
  imports: [
    CommonModule,
    SharedDefaultModule,
    ModalComponent,
    ButtonSolidComponent,
    ButtonOutlineComponent,
  ],
})
export class ConfirmationModalModule {}
