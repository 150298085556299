import { Injectable } from '@angular/core';
import { catchError, map, scan } from 'rxjs/operators';
import { GraphQLService } from '../../../core/services/graphql.service';
import { CustomerBranchesSubscribeGQL } from '../../../graphql/generated';
import { applySubscriptionChangesToItems } from '../../../shared/helpers/functions/applySubscriptionChangesToItems';

@Injectable({
  providedIn: 'root',
})
export class CustomerBranchService {
  constructor(
    private customerBranchesSubscribeGQL: CustomerBranchesSubscribeGQL,
    private graphQLService: GraphQLService,
  ) {}

  customerBranches$ = this.customerBranchesSubscribeGQL
    .subscribe({ limit: 1000 }, { fetchPolicy: 'no-cache' })
    .pipe(
      map((res) => {
        const unnulledData = {
          ...res.data?.customerBranchesSubscribe,
          items: res.data?.customerBranchesSubscribe?.items || [],
          itemIds: res.data?.customerBranchesSubscribe?.itemIds || [],
          changedItems: res.data?.customerBranchesSubscribe?.changedItems || [],
          removedItems: res.data?.customerBranchesSubscribe?.removedItems || [],
          addedItems: res.data?.customerBranchesSubscribe?.addedItems || [],
          totalCount: 99,
        };
        return unnulledData;
      }),
      scan((acc, result) => {
        return applySubscriptionChangesToItems(acc, result, '_id');
      }),
      map((res) => {
        // create new array to trigger angular change-detection
        return [...res.items];
      }),
      catchError((err) => {
        this.graphQLService.handleError(err);
        throw err;
      }),
    );

  activeCustomerBranches$ = this.customerBranches$.pipe(
    map((branches) => branches.filter((branch) => !branch.inactive)),
  );

  branchesICanAssign$ = this.customerBranches$.pipe(
    map((branches) => branches.filter((branch) => branch.iCanAssignToVacancy)),
  );
}
